var names = {
    at: 'Österreich',
    us: 'USA',
    ch: 'Schweiz',
    gb: 'Großbritannien',
    it: 'Italien',
    de: 'Deutschland'
}
function translate(telInput) {
    var id = telInput.attr('id');
    var flagContainer = $('#' + id).siblings('div');
    var countries = flagContainer.find('.country-list .country');
    countries.each(function () {
        $(this).find('.country-name').text(names[$(this).data('country-code')]);
    })
}
