function createComboBoxOptionsFromJson(
    data,
    key_value, 
    key_text,
    placeholder,
    default_value
) {
    var c_options = '';
    var default_value_found = false;
    for (var i in data) {
        var customer = data[i];
        c_txt = customer[key_text];
        c_val = customer[key_value];
        var is_default = false;
        if (default_value == c_val) {
            default_value_found = true;
            is_default = true;
        }
        c_options += '<option value="'
            + c_val
            + '"'
            + ((is_default) ? ' selected' : '')
            + '>'
            + c_txt
            + '</option>';
    }
    if (placeholder != undefined && placeholder != null) {
        c_options = '<option'
            + ((default_value_found) ? '' : ' selected')
            + ' disabled>'
            + placeholder
            + '</option>'
            + c_options;
    }
    return c_options;
}
